/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDtmKazpLCn8EWYOa6lW-Rs5C0i61JEbHM",
  "appId": "1:699543362802:web:67a22991bac8f2e1e5c46b",
  "authDomain": "schooldog-i-douglas-ga.firebaseapp.com",
  "measurementId": "G-YSKGHV21H7",
  "messagingSenderId": "699543362802",
  "project": "schooldog-i-douglas-ga",
  "projectId": "schooldog-i-douglas-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-douglas-ga.appspot.com"
}
